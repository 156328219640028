
import { defineComponent } from "vue";
import SectionCard from "@/components/common/SectionCard.vue";
import GoBackIcon from "@/components/icon-components/GoBackIcon.vue";

export default defineComponent({
  name: "ReportsPage",
  components: {
    SectionCard,
    GoBackIcon,
  },
  methods: {
    goToReport(routeName: string) {
      this.$router.push({ name: routeName });
    },
  },
});
